import Vue from 'vue';
import gql from 'graphql-tag';
import { DateTime as LuxonDateTime } from 'luxon';

import Loader from '@/components/Shared/Loader/Loader';
import apollo from '@/services/apollo';
import { BookingModel } from '../Bookings/Bookings';
import Button from '@/components/Admin/Button/Button';

export interface BookindDetailData {
  actionInProgress: boolean;
  booking: BookingModel | null;
  loading: boolean;
}

const BookingDetail = Vue.extend({
  data(): BookindDetailData {
    return {
      actionInProgress: false,
      booking: null,
      loading: true,
    };
  },

  methods: {
    approveBooking(event: Event) {
      event.preventDefault();
      if (!this.booking) return;

      this.updateBooking({
        ...this.booking,
        confirmed: true,
      });
    },

    cancelBooking(event: Event) {
      event.preventDefault();
      if (!this.booking) return;

      this.updateBooking({
        ...this.booking,
        confirmed: false,
      });
    },

    deleteBooking(event: Event) {
      event.preventDefault();

      const confirmed = window.confirm('Are you sure you want to delete this booking');
      if (!confirmed) return;

      this.actionInProgress = true;

      const mutation = gql`
        mutation deleteBooking($id: String!) {
          deleteBooking(id: $id) {
            ok
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables: {
          id: this.booking?.id || '',
        },
      })
        .then(() => {
          const currentDate = this.$route.query.date;
          this.$router.push(`/admin/bookings?refresh=true${this.$route.query.date ? `&date=${currentDate}` : ''}`);
        })
        .catch((err) => {
          console.log('Error deleting booking', err);
        })
        .finally(() => {
          this.actionInProgress = false;
        });
    },

    loadBooking() {
      this.loading = true;

      const { id } = this.$route.params;

      const query = gql`
        query getBooking($id: String!) {
          getBooking(id:$id) {
            confirmed
            created
            email
            firstName
            id
            lastName
            message
            phone
            selectedDate
            selectedDay
            selectedOffering
            selectedTime
          }
        }
      `;

      apollo.query({
        query,
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          this.booking = data?.getBooking || null;
        })
        .catch((err) => {
          console.log('Error loading booking', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateBooking(variables: BookingModel) {
      this.actionInProgress = true;

      const mutation = gql`
        mutation updateBooking(
          $confirmed: Boolean
          $email: String
          $firstName: String
          $id: String!
          $lastName: String
          $phone: String
          $selectedDate: String
          $selectedOffering: String
          $selectedTime: String
        ) {
          updateBooking(
            confirmed: $confirmed
            email: $email
            firstName: $firstName
            id: $id
            lastName: $lastName
            phone: $phone
            selectedDate: $selectedDate
            selectedOffering: $selectedOffering
            selectedTime: $selectedTime
          ) {
            ok
          }
        }
      `;

      apollo.mutate({
        mutation,
        variables,
      })
        .then(() => {
          this.booking = {
            ...variables,
          };

          // Update store value
          this.$emit('updateBookingItem', this.booking);
        })
        .catch((err) => {
          console.log('Error updating booking', err);
        })
        .finally(() => {
          this.actionInProgress = false;
        });
    },
  },

  mounted() {
    this.loadBooking();
  },

  name: 'booking-detail',

  render() {
    if (this.loading) {
      return <Loader />;
    }

    if (!this.booking) {
      return (
        <div class="dashboard-content">
          <h1>Booking not found</h1>
        </div>
      );
    }

    return (
      <div class="booking-detail">
        <header class="dashboard-header">
          <h1 class="dashboard-header__title">{this.booking.firstName} {this.booking.lastName}</h1>
        </header>

        <div class="dashboard-content">

          <div class="key-value">
            <span class="key">Name</span>
            <span class="value">{this.booking.firstName} {this.booking.lastName}</span>
          </div>

          <div class="key-value">
            <span class="key">Status</span>
            <div class="value">
              <span class={`generic-label ${this.booking.confirmed ? 'success' : ''}`}>
                {
                  this.booking.confirmed
                    ? 'Confirmed'
                    : <span class="sidebar-header__day-string">Pending</span>
                }
              </span>
            </div>

          </div>

          <div class="key-value">
            <span class="key">Phone</span>
            <span class="value">{this.booking.phone}</span>
          </div>

          <div class="key-value">
            <span class="key">Message</span>
            <span class="value">{this.booking.message || 'Empty'}</span>
          </div>

          <div class="key-value">
            <span class="key">Selected Date</span>
            <span class="value">{LuxonDateTime.fromISO(this.booking.selectedDay).toLocaleString({
              day: '2-digit',
              month: 'long',
              weekday: 'long',
            })}</span>
          </div>

          <div class="key-value">
            <span class="key">Selected Time</span>
            <span class="value">{this.booking.selectedTime}</span>
          </div>

          <div class="key-value">
            <span class="key">Package</span>
            <span class="value">{
              this.booking.selectedOffering
                ? this.booking.selectedOffering.split('|')[1].trim()
                : 'None selected'
            }</span>
          </div>

          <div class="key-value">
            <span class="key">Actions</span>
            <div class="actions value">
              {
                this.booking.confirmed
                  ? (
                    <a onClick={(event: Event) => this.cancelBooking(event)}>
                      <Button loading={this.actionInProgress} primary>Cancel</Button>
                    </a>
                  )
                  : (
                    <a onClick={(event: Event) => this.approveBooking(event)}>
                      <Button loading={this.actionInProgress} primary>Confirm</Button>
                    </a>
                  )
              }

              {
                !this.booking.confirmed && (
                  <router-link to={`/admin/bookings/${this.booking.id}/edit?date=${this.booking.selectedDay}`}>
                    <Button
                      loading={this.actionInProgress}
                      primary>
                      Edit
                    </Button>
                  </router-link>
                )
              }

              {
                !this.booking.confirmed && (
                  <a onClick={(event: Event) => this.deleteBooking(event)}>
                    <Button
                      danger
                      loading={this.actionInProgress}
                      primary
                    >
                      Delete
                    </Button>
                  </a>
                )
              }
            </div>
          </div>

        </div>
      </div>
    );
  },

  watch: {
    $route: 'loadBooking',
  },
});

export default BookingDetail;
